import { useTranslation } from "react-i18next";
import ViewAllProductsDataTable from "./view-all-products-datatable";
import { Suspense, useEffect, useRef, useState } from "react";
import AppLoading from "../app-loading/app-loading";
import { Toast } from "primereact/toast";
import ProductSearch from "./product-search";
import ProductService from "../../services/products/products.service";
import { OtherUserModel } from "../../models/all-user/edit-user-model";

const ViewAllProducts = () => {
  const { t } = useTranslation();
  const toast = useRef<Toast>(null);
  const [refreshPage, setRefreshPage] = useState(true);
  const [filteredProducts, setFilteredProducts] = useState<OtherUserModel[]>(
    []
  );
  const [productList, setProductList] = useState<OtherUserModel[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleSearch("");
  }, [refreshPage]);

  const getAllProducts = () => {
    ProductService.getAllAdminProducts().then((response) => {
      setProductList(response);
    });
  }

  useEffect(() => {
    getAllProducts()
  }, []);

  function handleSearch(query: string): void {
    setLoading(true);
    if (productList && productList.length > 0) {
      const searchedProducts = productList.filter(
        (product) =>
          product.name &&
          typeof product.name === "string" &&
          product.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredProducts(searchedProducts);
    } else {
      setFilteredProducts([]);
    }
    setTimeout(() => setLoading(false), 200);
  }

  const reloadPage = (val: boolean) => {
    setRefreshPage(val);
  };

  const handleEditProductSuccess = () => {
    getAllProducts();
    setRefreshPage(!refreshPage);
  };

  return (
    <>
      <Suspense fallback={<AppLoading />}>
        <Toast ref={toast} position="top-center" />
        <div className="layout-content mt-3 mb-3 grid grid-table-mobile">
          <div className="col-12">
            <div className="flex align-items-center justify-content-between flex-wrap gap-2 w-full card-container mb-0">
              <h5 className="ml-1 mb-0">{t("view_all_products")}</h5>
              <ProductSearch onSearch={handleSearch} reloadPage={reloadPage} setLoading={setLoading}/>
            </div>
          </div>
          <ViewAllProductsDataTable
            data={filteredProducts.length > 0 ? filteredProducts : productList}
            handleEditProductSuccess={handleEditProductSuccess}
          />
        </div>
        {loading && <AppLoading />}
      </Suspense>
    </>
  );
};

export default ViewAllProducts;
