import AppFooter from "../AppFooter";

const ShippingPolicy = () => {
    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card docs">
                        <h4>Shipping/Delivery Policy:</h4>

                        <h6>Shipping Methods:</h6>
                        <p>
                            Currently, there are two shipping methods adapted: 
                            Along with courier service, we offer home delivery service for the following cities: Bangalore, Mysore, K R Nagar, Tumkur, Hunsur, and Hassan.
                            For other cities not mentioned above, delivery will be through courier across India.
                        </p>

                        <h6>Shipping Costs:</h6>
                        <p>
                            Shipping costs are calculated based on:
                            <ul>
                                <li>Volumetric weight of the shipment</li>
                                <li>Distance from the shipping location to customer location</li>
                                <li>Courier service provider selected by the customer</li>
                            </ul>
                        </p>

                        <h6>Shipping Destinations:</h6>
                        <p>
                            Shipping destinations are identified based on the Pin Code of the location.
                        </p>

                        <h6>Delivery Timeframes:</h6>
                        <p>
                            <b>For Home delivery locations:</b> Delivery will be done using in-house logistics/fleet and pre-fixed delivery dates are set for various geographical areas. To know the exact delivery date to your location, call us at: 78910 11554.
                        </p>
                        <p>
                            <b>For shipments through Courier:</b> Delivery timeframe will be as per the courier service provider based on the distance.
                        </p>
                        <p>
                            To track your orders or for any assistance related to shipping and delivery information, please contact: 78910 11554.
                        </p>
                    </div>
                </div>
            </div>
            <AppFooter />
        </>
    );
};

export default ShippingPolicy;
