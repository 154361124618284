import { Button } from "primereact/button";
import { Column, ColumnEditorOptions } from "primereact/column";
import { DataTable, DataTableRowEditCompleteEvent } from "primereact/datatable";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";
import { useEffect, useRef, useState, CSSProperties } from "react";
import { Toast, ToastMessage } from "primereact/toast";
import { useTranslation } from "react-i18next";
import ProductService from "../../services/products/products.service";
import { ProductDetailModel, ProductsInfoModel } from "./products";
import { Tag } from "primereact/tag";
import { Dialog } from "primereact/dialog";
import ProductInfo from "./product-info";
import { OtherUserModel } from "../../models/all-user/edit-user-model";
import ClipLoader from "react-spinners/ClipLoader";

interface DataTableProps {
  data: OtherUserModel[];
  handleEditProductSuccess: any
}

const ViewAllProductsDataTable: React.FC<DataTableProps> = (props) => {
  const menuLeft: any = useRef(null);
  const { t } = useTranslation();
  const toast = useRef<Toast>(null);
  const [allProducts, setAllProducts] = useState<any>();
  const [showEditModal, setShowEditModal] = useState(false);
  const [categories, setCategories] = useState<any>();
  const [subCategories, setSubCategories] = useState<any>();
  const [updateProductInfo, setUpdateProductInfo] =
    useState<ProductDetailModel>();
  const [filteredSubCategories, setFilteredSubCategories] = useState<any>();
  const [loader, setLoader] = useState<boolean>();

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
    position: "absolute", // You can adjust this property if necessary
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const showMessage = (
    label: string,
    message: string,
    severity: ToastMessage["severity"]
  ) => {
    toast.current?.show({
      severity: severity,
      summary: label,
      detail: message,
      life: 3000,
    });
  };

  useEffect(() => {
    setAllProducts(props.data);
  }, [props.data]);

  useEffect(() => {
    setLoader(true);
    ProductService.getCategory().then((res) => {
      setCategories(res);
      setLoader(false);
    });

    ProductService.getSubCategory().then((res) => {
      setSubCategories(res);
      setLoader(false);
    });
  }, []);

  const measurementTypeItems = [
    { name: "Litre", measurementTypeID: 1 },
    { name: "Kilogram", measurementTypeID: 2 },
    { name: "Grams", measurementTypeID: 3 },
    { name: "Pieces", measurementTypeID: 4 },
  ];
  const trueFalseOptions = [
    { name: "true", value: true },
    { name: "false", value: false },
  ];

  const categoryOnChange = (e: DropdownChangeEvent) => {
    const filterSubCategories = subCategories?.filter(
      (eachItem: any) => eachItem?.categoryID == e?.value
    );
    setFilteredSubCategories(filterSubCategories);
  };

  const categoryEditor = (options: ColumnEditorOptions) => {
    let editOptionVal =
      typeof options.value === "string"
        ? options.rowData.categoryID
        : options.value;
    return (
      <Dropdown
        className="border-1 border-primary"
        value={editOptionVal}
        options={categories}
        optionLabel="name"
        optionValue="categoryID"
        style={{ width: "95%" }}
        onChange={categoryOnChange}
      />
    );
  };

  const subCategoryEditor = (options: ColumnEditorOptions) => {
    let editOptionVal =
      typeof options.value === "string"
        ? options.rowData.subCategoryID
        : options.value;
    return (
      <Dropdown
        className="border-1 border-primary"
        value={editOptionVal}
        options={filteredSubCategories}
        optionLabel="name"
        optionValue="subCategoryID"
        style={{ width: "95%" }}
        onChange={(e: DropdownChangeEvent) => {
          options.editorCallback!(e.value);
        }}
      />
    );
  };

  const nameEditor = (options: ColumnEditorOptions) => {
    return (
      <InputText
        type="text"
        value={options.value}
        style={{ width: "95%", border: "1px solid #090" }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          options.editorCallback!(e.target.value)
        }
      />
    );
  };

  const unitPriceEditor = (options: ColumnEditorOptions) => {
    return (
      <InputText
        value={options.value}
        style={{ width: "95%", border: "1px solid #090" }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          options.editorCallback!(e.target.value)
        }
      />
    );
  };

  const gstEditor = (options: ColumnEditorOptions) => {
    return (
      <InputText
        value={options.value}
        style={{ width: "95%", border: "1px solid #090" }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          options.editorCallback!(e.target.value)
        }
      />
    );
  };

  const measurementTypeEditor = (options: ColumnEditorOptions) => {
    let editOptionVal =
      typeof options.value === "string"
        ? options.rowData.measurementTypeID
        : options.value;
    return (
      <Dropdown
        className="border-1 border-primary"
        value={editOptionVal}
        options={measurementTypeItems}
        optionLabel="name"
        optionValue="measurementTypeID"
        style={{ width: "95%" }}
        onChange={(e: DropdownChangeEvent) => {
          options.editorCallback!(e.target.value);
        }}
      />
    );
  };

  const inStockEditor = (options: ColumnEditorOptions) => {
    return (
      <InputText
        type="number"
        value={options.value}
        style={{ width: "95%", border: "1px solid #090" }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          options.editorCallback!(e.target.value)
        }
      />
    );
  };

  const isDeletedEditor = (options: ColumnEditorOptions) => {
    return (
      <Dropdown
        className="border-1 border-primary"
        value={options.value}
        options={trueFalseOptions}
        optionLabel="name"
        optionValue="value"
        style={{ width: "95%" }}
        onChange={(e: DropdownChangeEvent) => {
          options.editorCallback!(e.value);
        }}
      />
    );
  };

  const onRowEditComplete = async (event: DataTableRowEditCompleteEvent) => {
    const data = [...allProducts];
    const { newData, index } = event;
    if (data[index] !== newData) {
      if (typeof newData.measurementTypeName === "number") {
        newData.measurementTypeID = newData.measurementTypeName;
        let measurementTypeData: any = measurementTypeItems.find(
          (x) => x.measurementTypeID === newData.measurementTypeID
        );
        newData.measurementTypeName = measurementTypeData.name;
      }
      const updatedProduct: ProductsInfoModel = {
        productID: newData.productID,
        subCategoryID: newData.subCategoryID,
        measurementTypeID: newData.measurementTypeID,
        name: newData.name,
        unitPrice: newData.unitPrice,
        gst: newData.gst,
        inStock: newData.inStock,
        isDeleted: newData.isDeleted,
      };
      let response = await ProductService.updateProduct(updatedProduct);
      if (response.isSuccess) {
        showMessage(
          "Update Products",
          "Product updated successfully",
          "success"
        );
        data[index] = newData as ProductsInfoModel;
        setAllProducts(data);
      } else {
        showMessage(
          "Update Products",
          response.message + ": Error in update product",
          "error"
        );
      }
    }
  };

  const deleteProduct = () => {
    ProductService.deleteProduct(updateProductInfo!.productID).then((res) => {
      console.log("Response of deleteing product", res);
    });
  };

  const menuItems = [
    {
      label: "Edit Product Info",
      icon: "pi pi-file-edit",
      command: () => {
        console.warn(updateProductInfo);
      },
      // command: () => {setShowEditModal(true)},
    },
    // {
    //     label: "Delete",
    //     icon:"pi pi-trash",
    //     command: () => {deleteProduct()}
    // }
  ];

  const isDeletedTemplate = (rowData: ProductDetailModel) => {
    let deletedValue = rowData.isDeleted ? "Deleted" : "Active";
    return (
      <Tag value={deletedValue} severity={getSeverity(rowData.isDeleted)} />
    );
  };
  const getSeverity = (value: boolean) => {
    switch (value) {
      case true:
        return "danger";

      case false:
        return "success";

      default:
        return "success";
    }
  };
  const otherActionTemplate = (rowData: ProductDetailModel) => {
    return (
      <div className="flex">
        {/* <Button icon="pi pi-plus" onClick={()=>{handleAddAdminAddress(rowData.userID)}}
                style={{ border: 'none',color: 'gray', background: 'none' }}
                className="p-button-rounded p-mr-2"/> */}

        {/* <Button icon="pi pi-ellipsis-v" onClick={(event: any) => {
                    if (menuLeft && menuLeft.current)
                        menuLeft.current.toggle(event);
                }} style={{ border: 'none',color: 'gray', background: 'none' }}
                className="p-button-rounded p-mr-2"/>         */}

        <Button
          icon="fas fa-edit"
          onClick={(event: any) => {
            setUpdateProductInfo(rowData);
            setShowEditModal(true);
          }}
          style={{ border: "none", color: "gray", background: "none" }}
          className="p-button-rounded p-mr-2"
        />
      </div>
    );
  };

  const handleOnSuccess = () => {
    setShowEditModal(false);
    showMessage("Update Products", "Product updated successfully", "success");
    props.handleEditProductSuccess()
  };

  return (
    <>
      {loader ? (
        <div className="flex-align-items-center justify-content-center p-4">
          <ClipLoader
            loading={loader}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
            cssOverride={override}
            color="#90ee90"
          />
        </div>
      ) : (
        <>
          <Toast ref={toast} position="top-center" />
          <div className="col-12">
            <div className="card border-noround p-2">
              <DataTable
                value={allProducts?.length > 0 ? allProducts : props.data}
                editMode="row"
                onRowEditComplete={onRowEditComplete}
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                stripedRows
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
              >
                <Column
                  field="categoryName"
                  header="Category"
                  style={{ width: "12%" }}
                />
                <Column
                  field="subCategoryName"
                  header="Sub Category"
                  style={{ width: "12%" }}
                />
                <Column
                  field="measurementTypeName"
                  header="Measurment Type"
                  style={{ width: "8%" }}
                />
                <Column
                  field="name"
                  header="Name"
                  editor={nameEditor}
                  style={{ width: "20%" }}
                />
                <Column
                  field="unitPrice"
                  header="Unit Price"
                  editor={unitPriceEditor}
                  style={{ width: "12%" }}
                />
                <Column
                  field="gst"
                  header="GST %"
                  editor={gstEditor}
                  style={{ width: "8%" }}
                />
                <Column
                  field="inStock"
                  header="In Stock"
                  editor={inStockEditor}
                  style={{ width: "8%" }}
                />
                <Column
                  field="isDeleted"
                  header="Is Deleted"
                  body={(options) => isDeletedTemplate(options)}
                  editor={isDeletedEditor}
                  style={{ width: "10%" }}
                />
                <Column
                  rowEditor
                  header="Action"
                  headerStyle={{ textAlign: "right" }}
                />
                <Column body={(options) => otherActionTemplate(options)} />
              </DataTable>
              {
                <Menu
                  popupAlignment={"left"}
                  model={menuItems}
                  popup
                  ref={menuLeft}
                  id="popup_menu_left"
                />
              }
            </div>
          </div>
          <Dialog
            visible={showEditModal}
            className="quickview w-50"
            breakpoints={{ "960px": "75vw", "641px": "90vw" }}
            modal
            onHide={() => setShowEditModal(false)}
          >
            <ProductInfo
              isUpdate={true}
              handleOnSuccess={handleOnSuccess}
              updateProductInfo={updateProductInfo}
              subCate={subCategories}
            />
          </Dialog>
        </>
      )}
    </>
  );
};

export default ViewAllProductsDataTable;
