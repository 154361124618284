import ApiConstant from "../../constants/api.constants";
import Helpers from "../../utils/helpers";
import api from "../api";

const tenantGuid = process.env.REACT_APP_TENAT_ID;

const getAllProducts = async () => {
  const apiGetProducts = ApiConstant.getHomeProducts + tenantGuid;
  return await api
    .get(apiGetProducts)
    .then(function (response: any) {
      if (response) {
        return response.data;
      } else {
        return null;
      }
    })
    .catch(function (error: any) {
      console.error("error reading get all categories data.", error);
    });
};

const getCategory = async () => {
  return await api
    .get(ApiConstant.getCategory)
    .then(function (response: any) {
      if (response) {
        return response.data;
      } else {
        return null;
      }
    })
    .catch(function (error: any) {
      console.error("error reading get all categories data.", error);
    });
};

const getSubCategory = async () => {
  return await api
  .get(ApiConstant.getSubCategory).then(function (response: any){
    if(response){
      return response.data;
    } else{
      return null;
    }
  }).catch(function (error: any){
    console.error("error reading get all sub categories data.", error)
  })
}

const getAllAdminProducts = async () => {
  const apiGetProducts = ApiConstant.getAllAdminProducts /* + tenantGuid */;
  return await api
    .get(apiGetProducts)
    .then(function (response: any) {
      if (response) {
        return response.data;
      } else {
        return null;
      }
    })
    .catch(function (error: any) {
      console.error("error reading get all categories data.", error);
    });
};

const saveNewProduct = async (product: any) => {
  // console.log("product from service", product);
  return await api
  .post(ApiConstant.saveProduct, product, {
    headers: {
      "content-type": "application/json",
    },
  })
  .then(function (response: any) {
    if (response) {
      return response.data;
    }
  })
  .catch(function (error) {
    Helpers.ConsoleLog(error, "error");
    return error;
  });
}

const saveProduct = async (product: any) => {
  // console.log("product from service", product);
  return await api
  .put(ApiConstant.saveProduct, product, {
    headers: {
      "content-type": "application/json",
    },
  })
  .then(function (response: any) {
    if (response) {
      return response.data;
    }
  })
  .catch(function (error) {
    Helpers.ConsoleLog(error, "error");
    return error;
  });
}

const updateProduct = async (product: any) => {
  // console.warn("Api Request", product);
  return await api
  .put(ApiConstant.updateProduct, product, {
    headers: {
      "content-type": "application/json",
    },
  })
  .then(function (response: any) {
    if (response) {
      return response.data;
    }
  })
  .catch(function (error) {
    Helpers.ConsoleLog(error, "error");
    return error;
  });
}

const deleteProduct = async (productId: number) => {
  try {
      const productDelete: any = await api
      .delete(ApiConstant.deleteProduct+`?productID=${productId}`)
      .then((res: any)=> {
          if(res)            
              return res.data;
          else
              return null;         
      })
      .catch(function (error: any) {
          console.error('error deleting user.', error);
      });
      const response = productDelete != null ? productId : null;        
      return response;
  }
  catch
  {
      console.log('error in delete user detail');
      return false;
  }
}

const ProductService = {
  getAllProducts,
  getCategory,
  getSubCategory,
  getAllAdminProducts,
  saveNewProduct,
  saveProduct,
  updateProduct,
  deleteProduct
};

export default ProductService;
